<template>
  <component
    :is="icon"
    :size="size"
    :color="color"
    :stroke-width="strokeWidth"
    :default-class="defaultClass"
  />
</template>
<script setup lang="ts">
import { computed, withDefaults } from 'vue'
import {
  Activity,
  Airplay,
  AlertCircle,
  AlertTriangle,
  ArrowDown,
  ArrowDownCircle,
  ArrowLeft,
  ArrowLeftRight,
  ArrowRight,
  ArrowUp,
  ArrowUpCircle,
  AtSign,
  BadgeCheck, BadgeHelp, BadgePlus,
  BarChartBig,
  BellRing,
  Bold, Brush, Calendar, CalendarClock, CalendarDays,
  Check,
  CheckCircle,
  CheckCircle2,
  CheckSquare,
  ChevronDown, ChevronLeft, ChevronRight, ChevronsLeft,
  ChevronsRight,
  ChevronsUpDown,
  ChevronUp,
  Circle,
  CircleDot,
  CircleUser,
  Clock,
  Copy, DoorOpen,
  Dot,
  Download,
  Drum,
  ExternalLink,
  Eye, Facebook, Flag, FolderSearch2,
  Gem, Gift,
  Globe,
  GripVertical, HelpCircle,
  History,
  Info,
  Instagram,
  Italic,
  KeyRound,
  Layers,
  LayoutDashboard,
  Lightbulb,
  Link,
  Link2,
  Linkedin,
  List,
  Lock,
  LogOut,
  Mail,
  Megaphone,
  MessageCircleQuestion,
  Minus,
  MinusCircle,
  MonitorPlay,
  Moon,
  MoreVertical,
  MoveLeft,
  MoveVertical,
  PauseCircle,
  Pencil,
  PencilRuler,
  Phone,
  Plus, PlusCircle,
  Receipt, RefreshCcw,
  RefreshCw,
  Repeat2,
  Rocket,
  RotateCw,
  Search,
  Settings,
  Share,
  Share2,
  ShieldCheck,
  ShoppingBasket,
  ShoppingCart,
  SmilePlus,
  TabletSmartphone,
  Text,
  Trash2,
  Trophy,
  Twitch,
  Underline,
  Upload,
  UserPlus,
  UserRoundCheck,
  Users2,
  Video,
  Wallet2,
  Waypoints,
  X,
  XCircle,
  Youtube,
  Zap,
} from 'lucide-vue-next'

const props = withDefaults(defineProps<{
  name: string
  size?: number
  color?: string
  strokeWidth?: number
  defaultClass?: string
}>(), {
  size: 24,
  color: 'currentColor',
  strokeWidth: 2,
  defaultClass: '',
})

const icon = computed(() => {
  return iconMap[props.name as keyof IconMap]
})

interface IconMap {
  [key: string]: typeof Airplay
}

const iconMap: IconMap = {
  Activity,
  Airplay,
  AlertCircle,
  AlertTriangle,
  BadgeCheck,
  Gem,
  History,
  Trophy,
  Clock,
  Gift,
  RotateCw,
  Mail,
  Drum,
  Layers,
  Circle,
  CheckCircle,
  CheckCircle2,
  CircleUser,
  PauseCircle,
  Link2,
  LogOut,
  ChevronRight,
  Flag,
  Share,
  Share2,
  ChevronLeft,
  X,
  MoveLeft,
  Upload,
  XCircle,
  Check,
  GripVertical,
  Pencil,
  Settings,
  Trash2,
  Repeat2,
  ShieldCheck,
  BarChartBig,
  BadgeHelp,
  FolderSearch2,
  Brush,
  Moon,
  Eye,
  Wallet2,
  Globe,
  MessageCircleQuestion,
  Instagram,
  Youtube,
  Linkedin,
  KeyRound,
  CircleDot,
  Megaphone,
  Facebook,
  Twitch,
  Phone,
  PencilRuler,
  AtSign,
  RefreshCw,
  RefreshCcw,
  Plus,
  UserPlus,
  Info,
  Waypoints,
  ChevronDown,
  TabletSmartphone,
  HelpCircle,
  Search,
  MinusCircle,
  PlusCircle,
  Bold,
  Italic,
  Underline,
  Link,
  List,
  Minus,
  ChevronsUpDown,
  Calendar,
  CalendarClock,
  CalendarDays,
  Lock,
  MoreVertical,
  ArrowUp,
  ArrowDown,
  ArrowDownCircle,
  ArrowUpCircle,
  Copy,
  CheckSquare,
  Text,
  Download,
  DoorOpen,
  UserRoundCheck,
  ShoppingBasket,
  ArrowLeftRight,
  ArrowRight,
  ExternalLink,
  Receipt,
  Users2,
  MoveVertical,
  Zap,
  Lightbulb,
  SmilePlus,
  Video,
  MonitorPlay,
  ShoppingCart,
  ArrowLeft,
  BadgePlus,
  Rocket,
  ChevronsRight,
  ChevronsLeft,
  LayoutDashboard,
  BellRing,
  ChevronUp,
  Dot,
}
</script>
