<template>
  <div
    class="transition-opacity duration-200"
    :class="[show ? 'opacity-100' : 'opacity-0']"
  >
    <div
      id="loyalty-layout"
      class="w-full min-h-screen h-full scroll-smooth flex flex-col justify-between font-inter dark:bg-ds-dark-bottom-most-layer"
    >
      <LoyaltyPageNotFound v-if="notFound" />
      <template v-else>
        <LoyaltyHeader
          v-if="!hideHeader && organization"
          :organization="organization"
        />
        <div
          class="flex-1 flex flex-col h-full"
        >
          <slot />
        </div>
        <footer />
      </template>
    </div>
  </div>
  <TropeeSunsetModal
    :show="showTropeeSunsetModal"
    @hide="showTropeeSunsetModal = false"
  />
</template>

<script lang="ts" setup>
import { RouteName } from '@/router'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, watch } from 'vue'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import LoyaltyHeader from '@/layouts/LoyaltyHeader.vue'
import { useLoyaltyProgramIdentifier } from '@core-lib/composables/loyalty/loyalty-program-identifier'
import {
  useOrganizationPublicProfileByIdentifierFetcher,
} from '@core-lib/composables/organization-fetcher'
import LoyaltyPageNotFound from '@/views/LoyaltyPageNotFound.vue'
import { useLoyaltyConfig } from '@core-lib/composables/loyalty/loyalty-config'
import TropeeSunsetModal from '@/components/loyalty/TropeeSunsetModal.vue'
import { useTropeeSunset } from '@/composables/tropee-sunset'

const route = useRoute()
const router = useRouter()

const show = computed(() => !!route?.name)
const meta = computed(() => route.meta)
const hideHeader = computed(() => meta.value.hideHeader)

watch([authFacade.isAuthenticated, meta], async ([isAuthenticated, meta]) => {
  if (!isAuthenticated && meta.requiresAuth) {
    await router.replace({ name: RouteName.LOGIN, query: { redirect: route.path } })
  }
}, { immediate: true })

const { loyaltyProgramIdentifier } = useLoyaltyProgramIdentifier()
const { organization } = useOrganizationPublicProfileByIdentifierFetcher(loyaltyProgramIdentifier.value)
const { notFound } = useLoyaltyConfig(loyaltyProgramIdentifier.value)
const { showTropeeSunsetModal } = useTropeeSunset()

const darkMode = computed(() => {
  if (!organization.value) return false
  return organization.value.profile.isDarkModeEnabled
})

// put dark class on body
watch(darkMode, (dark) => {
  if (dark) {
    document.body.classList.add('dark')
  } else {
    document.body.classList.remove('dark')
  }
}, { immediate: true })

onMounted(() => {
  showTropeeSunsetModal.value = true
})
</script>

<style lang="scss">
#loyalty-layout {
  .ds-input {
    .label {
      @apply text-[16px] leading-6 #{!important};
    }

    .field {
      @apply py-3 #{!important};
    }

    .input {
      @apply text-[16px] leading-6 #{!important};
      &::placeholder {
        @apply text-[16px] leading-6 #{!important};
      }
    }

    .helper-text {
      @apply text-sm #{!important};
    }
  }

  .ds-text {
    .label {
      @apply text-[16px] leading-6 #{!important};
    }

    .textarea-field {
      @apply text-[16px] leading-6 #{!important};
      &::placeholder {
        @apply text-[16px] leading-6 #{!important};
      }
    }

    .helper-text {
      @apply text-sm #{!important};
    }
  }
}
</style>
