<template>
  <div
    id="loyalty-navbar"
    ref="loyaltyNavbar"
    class="fixed max-w-[788px] top-0 left-4 max-[788px]:right-4 min-[788px]:w-full min-[788px]:left-1/2 min-[788px]:-translate-x-1/2 mt-3 sm:mt-[30px] mb-3 sm:pr-2 transition-all duration-150 ease-in-out z-[39] py-2 rounded-full border flex items-center"
    :class="[zeroScroll ? 'bg-transparent border-transparent max-sm:pr-0' : 'bg-white/[.52] dark:bg-ds-dark-bottom-layer/75 border-gray-300/25 backdrop-blur-[16px] max-sm:pr-2']"
  >
    <Transition
      name="fade-faster"
      mode="out-in"
    >
      <LoyaltyNavbarBackButton
        v-if="showBackToLoyaltyPage"
        :organization="organization"
        class="ml-2"
        :class="zeroScroll ? 'bg-white/[.24] ' : 'bg-white'"
      />
      <div
        v-else-if="!zeroScroll"
        class="ml-4 flex items-center sm:gap-2 sm:h-8"
      >
        <DsAvatarLetter
          :identifier="organization.id"
          :name="organization.name"
          :media-url="organization.profile.logoUrl"
          image-class="w-7 h-7 min-w-[28px] rounded-full"
          class="webkit-border-radius-fixer"
          placeholder-text-class="text-xs leading-5"
          :avatar-type="organization.profile.avatarType"
        />
        <div class="hidden sm:flex items-center">
          <div class="text-sm leading-8 font-semibold dark:text-white/[.87]">
            {{ organization.name }}
          </div>
          <OrganizationCertifiedBadge
            v-if="organization.isCertified"
            class="ml-1"
            size="small"
          />
        </div>
      </div>
    </Transition>
    <div class="ml-auto flex items-center gap-x-2">
      <DsIconButton
        class="transition-all duration-150 ease-in-out dark:!bg-ds-dark-bottom-layer md:hover:!bg-gray-50 hover:dark:!bg-ds-dark-top-layer border !border-gray-200 md:hover:!border-gray-200 dark:!text-white/[.87] dark:!border-ds-dark-top-layer hover:dark:!border-ds-dark-top-layer"
        :class="zeroScroll ? '!bg-white/[.24]' : '!bg-white'"
        :icon="{ name: 'Menu dots', type: 'bold' }"
        icon-size="small"
        mobile-size
        rounded
        @click="openMeatballsMenu = !openMeatballsMenu"
      />
      <button
        v-if="!isAuthenticated"
        class="h-10 px-5 py-2 rounded-full text-sm font-semibold text-ds-dark-primary border border-gray-200 md:hover:bg-gray-50 active:bg-gray-50 dark:border-ds-dark-top-layer dark:bg-ds-dark-bottom-layer hover:dark:bg-ds-dark-top-layer dark:text-white/[.87]"
        :class="zeroScroll ? 'bg-white/[.24]' : 'bg-white'"
        @click="connect"
      >
        {{ translate('join_for_free') }}
      </button>
      <template v-else>
        <div
          v-if="user"
          class="cursor-pointer rounded-full flex items-center justify-center w-10 h-10 min-w-[40px] md:hover:bg-gray-50 dark:bg-ds-dark-bottom-layer dark:md:hover:bg-ds-dark-top-layer border border-gray-200 dark:border-ds-dark-top-layer"
          :class="zeroScroll ? 'bg-white/[.24]' : 'bg-white'"
          @click="toggleNavbarMenu"
        >
          <DsAvatarLetter
            v-if="user"
            :identifier="user.id.slice(-4)"
            :name="user.username"
            :media-url="mustUser.avatar || ''"
            class="webkit-border-radius-fixer"
            image-class="w-7 h-7 min-w-[28px] rounded-full"
          />
        </div>
        <button
          class="group h-10 rounded-full border border-gray-200 flex items-center px-3 gap-x-2 cursor-pointer md:hover:bg-gray-50 active:bg-gray-50 dark:border-ds-dark-top-layer dark:bg-ds-dark-bottom-layer hover:dark:bg-ds-dark-top-layer dark:text-white/[.87]"
          :class="[zeroScroll ? 'bg-white/[.24] ' : 'bg-white']"
          @click="showPointsActivityModal = true"
        >
          <TropeeGold class="w-[22px] min-w-[22px] h-[17px]" />
          <DsAnimatedNumber
            v-if="availablePointsFetched"
            class="text-ds-dark-primary dark:text-white/[.87]"
            :number="availablePoints"
            number-container-class="loyalty-points-animated-number"
            :font-size-in-px="14"
            :font-weight="600"
            :container-height="28"
            :label="availablePoints !== 1 ? translator.translate('loyalty__points') : translator.translate('loyalty__point')"
          />
        </button>
      </template>
    </div>
    <LoyaltyNavbarMenu
      v-if="isAuthenticated && user"
      :show="openNavbarMenu"
      :force-active-item="forceActiveItem"
      :user="user"
      :organization="organization"
      @hide="openNavbarMenu = false"
    />
    <LoyaltyMeatballsMenu
      :key="isLoyaltyPage.toString()"
      :show="openMeatballsMenu"
      :organization-slug="organization.slug"
      :organization-name="organization.name"
      :organization-image="organization.profile.logoUrl || ''"
      :organization-id="organization.id"
      :organization-certified="organization.isCertified"
      :organization-avatar-type="organization.profile.avatarType"
      @hide="openMeatballsMenu = false"
    />
    <LoyaltyPointsActivityModal
      :show="showPointsActivityModal"
      @hide="showPointsActivityModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { RouteName } from '@/router'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import DsIconButton from '@core-design/components/Design/DsIconButton.vue'
import DsAnimatedNumber from '@core-design/components/Design/DsAnimatedNumber.vue'
import { useGetUserLoyaltyPoints, useThisUserPrivateFetcher } from '@core-lib/composables/user-fetcher'
import { capitalize } from '@core-lib/helpers/string'
import DsAvatarLetter from '@core-design/components/Design/DsAvatarLetter.vue'
import LoyaltyNavbarMenu from '@/components/loyalty/LoyaltyNavbarMenu.vue'
import LoyaltyMeatballsMenu from '@/components/loyalty/LoyaltyMeatballsMenu.vue'

import { useToaster } from '@core-lib/composables/toasters'
import translator from '@core-lib/webapp-services/i18n/translator'
import { OrganizationPublicProfile } from '@core-lib/openapi/client/api'
import { UserSocialLink } from '@core-lib/models/social'
import OrganizationCertifiedBadge from '@core-design/components/ComponentsLayout/Loyalty/OrganizationCertifiedBadge.vue'
import { useI18n } from '@core-lib/composables/i18n'
import { useIsLoyalty } from '@core-lib/composables/loyalty/is-loyalty'
import LoyaltyNavbarBackButton from '@core-lib/components/loyalty/LoyaltyNavbarBackButton.vue'
import { ActionToTrack, useTrackLastAction } from '@core-lib/composables/track-last-action'
import TropeeGold from '@core-design/assets/icons/loyalty/TropeeGold.vue'
import LoyaltyPointsActivityModal from '@/components/loyalty/LoyaltyPointsActivityModal.vue'
import { useTropeeSunset } from '@/composables/tropee-sunset'

const props = defineProps<{
  organization: OrganizationPublicProfile
}>()

const route = useRoute()
const { notify } = useToaster()
const { setLastAction } = useTrackLastAction(ActionToTrack.SIGNUP_SOURCE)
const { showTropeeSunsetModal } = useTropeeSunset()

const { isLoyaltyPage } = useIsLoyalty()
const { translate } = useI18n({ prefix: 'auth__' })
const isAuthenticated = authFacade.isAuthenticated
const { user } = useThisUserPrivateFetcher()
const mustUser = computed(() => {
  if (!user.value) throw new Error('Tried to access mustUser before it is ready')
  return user.value
})

const openNavbarMenu = ref(false)
const openMeatballsMenu = ref(false)
const showPointsActivityModal = ref(false)
const forceActiveItem = ref<number | null>(null)

const scrollY = ref(0)
const zeroScroll = computed(() => scrollY.value === 0)
const isRewardPage = computed(() => route.name === RouteName.LOYALTY_REWARD)

const loyaltyNavbar = ref<HTMLElement | null | undefined>(null)

const { availablePoints, isFetched: availablePointsFetched } = useGetUserLoyaltyPoints({
  type: 'orgSlug',
  orgSlug: props.organization.slug,
})

const showBackToLoyaltyPage = computed(() => isRewardPage.value)

function handleScroll() {
  if (!loyaltyNavbar.value) return
  scrollY.value = window.scrollY
}

const initiators = {
  scrollToTop: 'scroll_to_top',
}

function connect() {
  showTropeeSunsetModal.value = true
  // setLastAction('join_for_free')
  // openNavbarMenu.value = false
  // authFacade.showModal({ code: initiators.scrollToTop })
}

function toggleNavbarMenu() {
  openNavbarMenu.value = !openNavbarMenu.value
}

function checkInitiator() {
  const initiator = authFacade.getAuthInitiator<unknown>()
  if (!initiator) return
  if (initiator.code === initiators.scrollToTop) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

watch(isAuthenticated, isAuthenticated => {
  if (!isAuthenticated) return
  checkInitiator()
})

onMounted(async () => {
  window.addEventListener('scroll', handleScroll)

  if (route.query.provider && Object.values(UserSocialLink).includes(route.query.provider as UserSocialLink)) {
    if (!route.query.error) {
      notify({
        text: capitalize(translator.translate('settings__account_successfully_added')),
        type: 'success',
      })
    }
    openNavbarMenu.value = true
    forceActiveItem.value = 2
  }
})

onUnmounted(() => scrollY.value = 0)
</script>

<style lang="scss">
.loyalty-points-animated-number:before {
  content: "";
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  @apply pointer-events-none absolute top-0 left-0 z-[1] w-full h-[10px] group-hover:hidden dark:bg-none;
}

.loyalty-points-animated-number:after {
  content: "";
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  @apply pointer-events-none absolute bottom-0 left-0 z-[1] w-full h-[10px] group-hover:hidden dark:bg-none;
}
</style>
