<template>
  <DsBottomSheet
    :show="show"
    close-button
    :back-button="activeItem !== null"
    wrapper-class="font-inter"
    content-class="!p-3 overflow-y-auto"
    @hide="closeModal"
    @back="goBack"
  >
    <template #title>
      <span v-if="showLoyaltyWalletConnectModal">{{ translate('connect_wallet') }}</span>
      <span
        v-else
        class="flex items-center gap-x-3"
      >
        <DsAvatarLetter
          v-if="activeItem === null"
          :identifier="user.id.slice(-4)"
          :name="user.username"
          :media-url="user.avatar"
          class="webkit-border-radius-fixer"
          image-class="w-6 h-6 min-w-[24px] rounded-full object-cover border border-gray-200"
        />
        <span>{{ activeItem === null ? user.username : items[activeItem - 1].name }}</span>
      </span>
    </template>
    <LoyaltyConnectWallet
      v-if="showLoyaltyWalletConnectModal"
      class="p-3"
      @connect="handleUserUpdated"
    />
    <DsMenuWithContent
      v-else
      :items="items"
      :force-active-item="activeItem"
      content-class="p-3"
      @item-change="menuItemClickCallback"
    >
      <template #item1>
        <LoyaltyUserProfilePage
          :user="user"
          @user-updated="handleUserUpdated"
        />
      </template>
      <template #item2>
        <LoyaltyUserLinkedAccounts
          :user="user"
          @user-updated="handleUserUpdated"
        />
      </template>
    </DsMenuWithContent>
    <template
      v-if="activeItem === null"
      #footer
    >
      <ClLoyaltyNavbarMenuFooter
        @signup="goToHome"
        @find-out-more="goToLandingPageNewTab(`utm_source=tropee&utm_medium=page&utm_campaign=profile_menu_cta&utm_content=${organizationSlug}`)"
      />
    </template>
  </DsBottomSheet>
</template>
<script setup lang="ts">
import DsBottomSheet from '@core-design/components/Design/DsBottomSheet.vue'
import DsMenuWithContent from '@core-design/components/Design/DsMenuWithContent.vue'
import { computed, ref, watch } from 'vue'
import { useGoToLandingPage } from '@core-lib/composables/go-to-landing-page'
import { useThisUserPrivateFetcher } from '@core-lib/composables/user-fetcher'
import LoyaltyUserProfilePage from '@core-lib/components/settings/LoyaltyUserProfilePage.vue'
import LoyaltyUserLinkedAccounts from '@core-lib/components/settings/LoyaltyUserLinkedAccounts.vue'
import { useRouter } from 'vue-router'
import authFacade from '@core-lib/webapp-services/auth/auth-facade'
import { OrganizationPublicProfile, UserPrivateProfile } from '@core-lib/openapi/client/api'
import DsAvatarLetter from '@core-design/components/Design/DsAvatarLetter.vue'
import ClLoyaltyNavbarMenuFooter from '@core-design/components/ComponentsLayout/Loyalty/ClLoyaltyNavbarMenuFooter.vue'
import { useLoyaltyConnectWallet } from '@core-lib/composables/connect-wallet'
import { useI18n } from '@core-lib/composables/i18n'
import LoyaltyConnectWallet from '@core-lib/components/auth/LoyaltyConnectWallet.vue'
import { capitalize } from 'lodash'
import translator from '@core-lib/webapp-services/i18n/translator'
import { useTropeeSunset } from '@/composables/tropee-sunset'

const props = withDefaults(defineProps<{
  show: boolean
  organization: OrganizationPublicProfile
  user: UserPrivateProfile
  forceActiveItem?: number | null
}>(), {
  forceActiveItem: null,
})

const emit = defineEmits<{
  (e: 'hide'): void
}>()

const router = useRouter()
const { translate } = useI18n({ prefix: 'general__' })

const organizationSlug = computed(() => props.organization.slug)

const items = [
  {
    name: capitalize(translator.translate('settings__profile')),
    icon: 'CircleUser',
  },
  {
    name: capitalize(translator.translate('settings__apps')),
    icon: 'Link2',
  },
  {
    name: capitalize(translator.translate('general__log_out')),
    icon: 'LogOut',
    hideArrow: true,
  },
]

const activeItem = ref<number | null>(props.forceActiveItem)
watch(() => props.forceActiveItem, (value) => {
  activeItem.value = value
})

function menuItemClickCallback(item: number | null) {
  activeItem.value = item
  if (activeItem.value === 3) {
    authFacade.logout()
    emit('hide')
  }
}

const { refetch: refetchPrivateUser } = useThisUserPrivateFetcher()
const handleUserUpdated = () => refetchPrivateUser()

const { goToLandingPageNewTab } = useGoToLandingPage()
const { showTropeeSunsetModal } = useTropeeSunset()

function goToHome() {
  closeModal()
  showTropeeSunsetModal.value = true

  // const url = router.resolve({ name: HolderRouteName.HOMEPAGE }).href
  // const origin = window.location.origin
  //
  // window.open(`${origin}${url}?utm_source=tropee&utm_medium=page&utm_campaign=profile_menu_cta&utm_content=${organizationSlug.value}`, '_blank')
}

const { showLoyaltyWalletConnectModal, hasLoyaltyWalletError } = useLoyaltyConnectWallet()

function goBack() {
  if (showLoyaltyWalletConnectModal.value) {
    showLoyaltyWalletConnectModal.value = false
    return
  }
  hasLoyaltyWalletError.value = false
  activeItem.value = null
}

function closeModal() {
  emit('hide')
  activeItem.value = null
  showLoyaltyWalletConnectModal.value = false
  hasLoyaltyWalletError.value = false
}

</script>

<style scoped>

</style>
